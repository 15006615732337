// UsersListPage.js

import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../services/axiosInstance';
import {
    Box, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Typography, Button
} from '@mui/material';
import { useParams, Link } from 'react-router-dom';

const UsersListPage = (props) => {
    const { client } = useParams();
    const [users, setUsers] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState(50);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const forwardURL = `/register/${client}`;
    const assessmentMapping = {
        'maturity-assessment': 'Resource Capability Assessment',
        'project-assessment': 'Project-Delivery Assessment',
        'platform-solution-assessment': 'Platform-Solution Assessment'
    };

    const isMounted = useRef(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                // Use relative URL; axiosInstance handles baseURL and headers
                const response = await axiosInstance.get(`/userinfo/registeredUsers/${client}`);

                if (isMounted.current) {
                    const usersData = Array.isArray(response.data) ? response.data : [];
                    setUsers(usersData);
                }
            } catch (err) {
                if (isMounted.current) {
                    if (err.response && err.response.status === 401) {
                        setError('Authentication failed. Please log in again.');
                        // Optionally, redirect to login page
                    } else {
                        setError('Failed to fetch users: ' + err.message);
                    }
                    setUsers([]); // Ensure users is an array
                }
                console.error(err);
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchUsers();

        return () => {
            isMounted.current = false;
        };
    }, [client]);

    const handleLoadMore = () => {
        setVisibleUsers((prev) => prev + 20);
    };

    // Register and delete new user should be able to do only Admin

    const handleDelete = async (userId) => {
        try {
            // Use relative URL; axiosInstance handles baseURL and headers
            await axiosInstance.delete(`/userinfo/registeredUsers/${userId}`);

            setUsers((prevUsers) => prevUsers.filter(user => user.userId !== userId));
        } catch (error) {
            if (error.response) {
                setError('Failed to delete user: ' + error.response.data.message);
                console.error('Delete error:', error.response.data.message);
            } else {
                setError('Failed to delete user: ' + error.message);
                console.error('Delete error:', error);
            }
        }
    };

    const handleExportProgress = async () => {
        const userExportData = [];
        let totalRegisteredUsers = 0;
        let totalRegistered = 0;
        let totalActive = 0;
        let totalCompleted = 0;
        let usersCompletedAll = 0;

        await Promise.all(users.map(async (user) => {
            const userId = user.userId;
            const email = user.email;

            // Assuming these functions are passed via props
            const registeredAssessments = await props.fetchUserRegisteredApplicationsForSpecificUser(userId) || [];
            const activeAssessments = await props.fetchUserActiveApplicationsForSpecificUser(userId) || [];
            const completedAssessments = await props.fetchUserCompleteApplicationsForSpecificUser(userId) || [];

            if (registeredAssessments.length > 0) {
                totalRegisteredUsers++;
            }
            totalRegistered += registeredAssessments.length;
            totalActive += activeAssessments.length;
            totalCompleted += completedAssessments.length;

            if (registeredAssessments.length > 0 && registeredAssessments.length === completedAssessments.length) {
                usersCompletedAll++;
            }

            userExportData.push(`
Email: ${email}
Registered Assessments: ${registeredAssessments.join(', ') || 'None'}
Assessments In Progress: ${activeAssessments.join(', ') || 'None'}
Completed Assessments: ${completedAssessments.join(', ') || 'None'}
            `);
        }));

        userExportData.push(`
====== Summary ======
Total Users: ${users.length}
Total Registered Users: ${totalRegisteredUsers}
Total Users Who Completed All Assessments: ${usersCompletedAll}
Total Registered Assessments: ${totalRegistered}
Total Assessments In Progress: ${totalActive}
Total Completed Assessments: ${totalCompleted}
        `);

        const blob = new Blob([userExportData.join('\n\n')], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'users_progress.txt';
        link.click();
    };

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    // Ensure users is always an array
    const safeUsers = Array.isArray(users) ? users : [];

    return (
        <Box sx={{ width: '100%', marginTop: 20 }}>
            <TableContainer
                component={Paper}
                sx={{
                    maxHeight: '400px',
                    overflowY: 'auto',
                    overflowX: 'auto',
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Assessments</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {safeUsers.length > 0 ? (
                            safeUsers.slice(0, visibleUsers).map((user) => (
                                <TableRow key={user.userId || Math.random()}>
                                    <TableCell>
                                        {(user.application || '').split(',').map((app, index) => {
                                            const trimmedApp = app.trim();
                                            const readableName = assessmentMapping[trimmedApp] || trimmedApp;
                                            return (
                                                <Typography key={index} variant="body2">{readableName}</Typography>
                                            );
                                        })}
                                    </TableCell>
                                    <TableCell>{user.email || 'N/A'}</TableCell>
                                    <TableCell>{user.clientId || 'N/A'}</TableCell>
                                    <TableCell>{user.role || 'N/A'}</TableCell>
                                    <TableCell>
                                        <Button
                                            onClick={() => handleDelete(user.userId)}
                                            variant="contained"
                                            color="error"
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    <Typography>No registered users found for the client.</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                {visibleUsers < users.length && (
                    <Button
                        onClick={handleLoadMore}
                        variant="contained"
                        color="primary"
                    >
                        Load More
                    </Button>
                )}
            </Box>
            <Box sx={{ marginTop: 2, textAlign: 'center', display: 'flex', justifyContent: 'center', gap: 4 }}>
                <Button
                    component={Link}
                    to={forwardURL}
                    variant="contained"
                    color="success"
                >
                    Register New User
                </Button>
                <Button
                    onClick={handleExportProgress}
                    variant="contained"
                    color="success"
                >
                    Export Users Progress
                </Button>
            </Box>
        </Box>
    );
};

export default UsersListPage;
