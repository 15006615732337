import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Card, CardContent, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LoginPage = ({ setAuthToken }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const isMounted = useRef(true);

    const backendIp = window.location.hostname;
    const protocol = 'http' //window.location.protocol.includes('https') ? 'https' : 'http';
    const backendPort = 4003;

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleLogin = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await axios.post(`/api/auth/login`, { email, password });

            localStorage.setItem('token', response.data.token);

            setAuthToken(response.data.token);

            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                console.log('Token successfully retrieved');
            } else {
                console.error('Token retrieval failed.');
            }

            if (response.data.role === 'Admin') {
                navigate(`/registeredUsers/${response.data.client}`);
            } else {
                navigate(`/${response.data.userId}`);
            }

            if (isMounted.current) {
                setSuccess('Login successful! Redirecting...');

                // Clear success message after 3 seconds
                setTimeout(() => {
                    if (isMounted.current) {
                        setSuccess('');
                    }
                }, 3000);
            }

        } catch (err) {
            console.error('Login error:', err);
            if (isMounted.current) {
                setError('Login failed. Please check your credentials.');
            }
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                    <Box sx={{ minHeight: 150 }}></Box>
                </Grid>
                <Card sx={{ borderRadius: "25px" }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>Login</Typography>
                        {error && <Typography color="error">{error}</Typography>}
                        {success && <Typography color="primary">{success}</Typography>}
                        <form onSubmit={handleLogin}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="E-mail"
                                        fullWidth
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Password"
                                        fullWidth
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                    >
                                        Login
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default LoginPage;
