import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ element }) => {
    const authToken = localStorage.getItem('token');

    // Determine if the user is authenticated and the token is valid
    const isAuthenticated = !!authToken;
    let isExpired = false;

    if (isAuthenticated) {
        try {
            const decoded = jwtDecode(authToken);
            isExpired = decoded.exp * 1000 < Date.now(); // Check if the token is expired
        } catch (error) {
            console.error('Token decoding failed:', error);
            isExpired = true; // If decoding fails, consider the token expired
        }
    }

    // Redirect to login if not authenticated or if the token is expired
    if (!isAuthenticated || isExpired) {
        console.log('Token is invalid or expired, navigating to login.');
        localStorage.removeItem('token'); // Clean up expired or invalid token
        return <Navigate to="/login" />;
    }

    // If authenticated and token is valid, render the requested element
    return element;
};

export default PrivateRoute;
