import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../../pages/HomePage.js';
import RolesMenu from '../../pages/RolesMenu.js';
import QuestionsMenu from '../../pages/QuestionsMenu.js';
import CertificationsMenu from '../../pages/CertificationsMenu.js';
import CompletePage from '../../pages/CompletePage.js';
import RegisterPage from '../../pages/RegisterPage.js';
import UsersListPage from '../../pages/UsersListPage.js';
import LoginPage from '../../pages/LoginPage.js';
import PrivateRoute from '../../pages/PrivateRoute.js';
import ProtectedPage from '../../pages/ProtectedPage.js';
import ResetPasswordPage from '../../pages/ResetPasswordPage.js';

const MyRoutes = (props) => {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage setAuthToken={props.setAuthToken} />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

            <Route
                path="/:userId"
                element={
                    <PrivateRoute
                        element={
                            <HomePage
                                userActiveAssessment={props.userActiveAssessment}
                                fetchUserActiveAssessment={props.fetchUserActiveAssessment}
                                fetchUserActiveComponent={props.fetchUserActiveComponent}
                                userActiveComponent={props.userActiveComponent}
                                setUserApplication={props.setUserApplication}
                                userApplication={props.userApplication}
                                setUserActiveApplication={props.setUserActiveApplication}
                                handleUserActiveApplicationUpdate={props.handleUserActiveApplicationUpdate}
                                fetchUserActiveApplicationForGivenApplication={props.fetchUserActiveApplicationForGivenApplication}
                                fetchUserActiveApplicationsForSpecificUser={props.fetchUserActiveApplicationsForSpecificUser}
                                userActiveApplicationsList={props.userActiveApplicationsList}
                                fetchUserCompleteApplicationsForSpecificUser={props.fetchUserCompleteApplicationsForSpecificUser}
                                userCompleteApplicationsList={props.userCompleteApplicationsList}
                                fetchUserActiveCriteria={props.fetchUserActiveCriteria}
                                fetchUserActiveRole={props.fetchUserActiveRole}
                                userRegisteredApplicationsList={props.userRegisteredApplicationsList}
                                fetchUserRegisteredApplicationsForSpecificUser={props.fetchUserRegisteredApplicationsForSpecificUser}
                            />
                        }
                    />
                }
            />

            <Route
                path="/:application/roles/:userId"
                element={
                    <PrivateRoute
                        element={
                            <RolesMenu
                                handleUserUpdateRole={props.handleUserUpdateRole}
                                userActiveCriteria={props.userActiveCriteria}
                                fetchUserActiveCriteria={props.fetchUserActiveCriteria}
                                fetchUserActiveRole={props.fetchUserActiveRole}
                                userActiveRole={props.userActiveRole}
                                userApplication={props.userApplication}
                            />
                        }
                    />
                }
            />

            <Route
                path="/:application/certifications/:userId/:roles"
                element={
                    <PrivateRoute
                        element={
                            <CertificationsMenu
                                handleUserUpdateCertifications={props.handleUserUpdateCertifications}
                                userActiveCriteria={props.userActiveCriteria}
                                fetchUserActiveCriteria={props.fetchUserActiveCriteria}
                                fetchUserActiveRole={props.fetchUserActiveRole}
                                userActiveRole={props.userActiveRole}
                                userApplication={props.userApplication}
                            />
                        }
                    />
                }
            />

            <Route
                path="/:application/questions/:userId"
                element={
                    <PrivateRoute
                        element={
                            <QuestionsMenu
                                handleAnswerCreate={props.handleAnswerCreate}
                                handleUserActiveAssessmentUpdate={props.handleUserActiveAssessmentUpdate}
                                userActiveAssessment={props.userActiveAssessment}
                                fetchUserActiveAssessment={props.fetchUserActiveAssessment}
                                handleUserActiveComponentUpdate={props.handleUserActiveComponentUpdate}
                                userActiveComponent={props.userActiveComponent}
                                fetchUserActiveComponent={props.fetchUserActiveComponent}
                                userApplication={props.userApplication}
                                handleUserActiveCriteriaUpdate={props.handleUserActiveCriteriaUpdate}
                                userActiveCriteria={props.userActiveCriteria}
                                fetchUserActiveCriteria={props.fetchUserActiveCriteria}
                                userActiveRole={props.userActiveRole}
                                fetchUserActiveRole={props.fetchUserActiveRole}
                            />
                        }
                    />
                }
            />

            <Route
                path="/:application/complete/:userId"
                element={
                    <PrivateRoute
                        element={<CompletePage />}
                    />
                }
            />

            <Route
                path="/register/:client"
                element={
                    <PrivateRoute
                        element={<RegisterPage />}
                    />
                }
            />

            <Route
                path="/registeredUsers/:client"
                element={
                    <PrivateRoute
                        element={
                            <UsersListPage
                                fetchUserRegisteredApplicationsForSpecificUser={props.fetchUserRegisteredApplicationsForSpecificUser}
                                fetchUserActiveApplicationsForSpecificUser={props.fetchUserActiveApplicationsForSpecificUser}
                                fetchUserCompleteApplicationsForSpecificUser={props.fetchUserCompleteApplicationsForSpecificUser}
                            />
                        }
                    />
                }
            />

        </Routes>
    );
};

export default MyRoutes;
