import { RadioGroup, FormControlLabel, Radio, FormControl, Grid, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import rolesConfig from '../../data/roles.json';
import { useNavigate } from "react-router-dom";
import {useParams} from "react-router-dom";

const UserAdd = (props) => {
    const [formIsValid, setFormIsValid] = useState(false);
    const [roleValue, setRoleValue] = useState('');

    const navigate = useNavigate();

    const params = useParams()

    console.log("USERID: ", params.userId)

    useEffect(async() => {
        await props.fetchUserActiveCriteria(params.userId)
        await props.fetchUserActiveRole(params.userId)
        console.log("ROLES", props.userActiveRole)
        
        if (props.userActiveCriteria === -1) {
            navigate(`/${params.application}/complete/${params.userId}`);
        } else if (props.userActiveCriteria >= 1 && Object.values(rolesConfig['roles']).map(r => r.role).includes(props.userActiveRole) ) {
            navigate(`/${params.application}/questions/${params.userId}`);
        }

    }, [props, params.application, params.roles, params.userId, navigate])


    const handleChange = (event) => {
        setRoleValue(event.target.value);
    };

    useEffect(() => {
        console.log("Selected Role: ", roleValue);
      }, [roleValue]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFormIsValid(
                roleValue !== ''
            );
        }, 500);

        return () => {
            clearTimeout(timeout);
        };

    }, [roleValue]);

    const submitHandler = (event) => {
        event.preventDefault();
        const updateUser = JSON.stringify({
            [params.userId]: {
                // "name": nameState.value,
                // "email": emailState.value,
                "role": roleValue
            }
        });
        props.handleUserUpdateRole(params.userId, roleValue);
        localStorage.setItem('rolesObject', JSON.stringify(updateUser));
        navigate(`/${params.application}/certifications/${params.userId}/${roleValue}`);
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={12} sx={{ minHeight: "20px" }}></Grid>
            <Grid item xs={12} md={10}>
                <FormControl fullWidth>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={roleValue}
                        onChange={handleChange}
                    >
                        {Object.entries(rolesConfig['roles']).map(([id, role]) => 
                            <FormControlLabel 
                                key={id}
                                value={role.role}
                                control={<Radio color="secondary"/>}
                                label={role.role}
                                labelPlacement="end"
                                style={{minWidth:"95%", marginLeft: "100px"}}
                            />
                        )}
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
                <Button color="secondary" type="submit" onClick={submitHandler} disabled={!formIsValid} variant="outlined" style={{ minWidth: '100%', margin: '5px 0px 5px 0px', padding: '10px' }}>
                    Continue
                </Button>
            </Grid>
        </Grid>
    );
}

export default UserAdd;