import { Tabs, Tab, Grid, Card, CardContent, Box } from '@mui/material';
import React, { useState } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import UserAdd from '../components/ui/RolesAdd';

const UserMenu = (props) => {
    const [useTabState, setTabState] = useState(0);

    const handleChange = (event, newValue) => {
        setTabState(newValue);
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
            
        >
            <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                    <Box sx={{ minHeight: 150 }}></Box>
                </Grid>
                <Card sx={{ borderRadius: "25px" }}>
                    <CardContent sx={{ minWidth: "100%", margin: "0px", padding: "0px" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={{ xs: 3, md: 3 }}
                            columnSpacing={{ xs: 2, md: 3 }}
                            sx={{ backgroundColor: "#08440c", minWidth: "100%", margin: "0px", padding: "0px" }}
                        >
                            <Grid item xs={12} md={10}>
                                <Tabs indicatorColor="primary" textColor="primary" value={useTabState} onChange={handleChange} aria-label="icon label tabs example">
                                    <Tab sx={{ color: "rgb(255, 255, 255, 0.7)" }} icon={<PersonAddIcon></PersonAddIcon>} label="Select Role" />
                                </Tabs>
                            </Grid>
                        </Grid>
                        {
                            {
                                '0'
                                    : 
                                <UserAdd 
                                    handleUserUpdateRole={props.handleUserUpdateRole} 
                                    userActiveCriteria={props.userActiveCriteria} 
                                    fetchUserActiveCriteria={props.fetchUserActiveCriteria} 
                                    fetchUserActiveRole={props.fetchUserActiveRole}
                                    userActiveRole={props.userActiveRole}
                                    userApplication={props.userApplication}                                    />
                            }[useTabState]
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );

}

export default UserMenu;