import { Grid, Typography } from '@mui/material';
import { Button} from '@mui/material';
import { useNavigate } from "react-router-dom";
import {useParams} from "react-router-dom";
import React from 'react';

const Complete = () => {

    const navigate = useNavigate();

    const params = useParams()    

    
    const submitHandler = async (event) => {
        event.preventDefault();
        console.log("Returining back to home page");
        navigate(`/${params.userId}`);

    };
    
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={12} sx={{ minHeight: "20px" }}></Grid>
            <Grid item xs={12} md={10}>
                <Typography sx={{ fontSize: "22px", marginBottom: "8px" }}>Your answers have been recorded.  
                </Typography>
                <Typography sx={{  fontSize: "22px", marginBottom: "8px" }}>Thank you for taking the time to answer the questions.</Typography>
                <Button
                            color="secondary"
                            type="submit"
                            onClick={submitHandler}
                            variant="outlined"
                            style={{ minWidth: '100%', margin: '5px 0', padding: '10px' }}
                        >
                            Click to go back to Home page
                        </Button>
            </Grid>
        </Grid>
    );
}

export default Complete;