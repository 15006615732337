import axios from 'axios';

const backendIp = window.location.hostname;
const protocol = 'http' //window.location.protocol.includes('https') ? 'https' : 'http';
const backendPort = 4003;

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: `/api`,
});

// Intercept requests to attach the token
axiosInstance.interceptors.request.use(
    (config) => {
        // Retrieve the token from localStorage or props (modify as needed)
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`; // Attach the token
        }

        return config;
    },
    (error) => {
        // Handle the error
        return Promise.reject(error);
    }
);

export default axiosInstance;
