import { RadioGroup, List, ListItem, ListItemAvatar, Avatar, ListItemText, FormControlLabel, Radio, Typography, Grid, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axiosInstance from '../../services/axiosInstance';
import {useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const QuestionMaturityAssessment = (props) => {

    const navigate = useNavigate();
    const params = useParams()
    
    const backendIp = window.location.hostname
    const protocal = 'http' //window.location.protocol.includes('https') ? 'https' : 'http';
    const backendPort = 4003

    const location = useLocation();
    console.log("PROP ROLE ", props.userActiveRole)

    const paramsRoles2 = location.state?.role

    const paramsRoles = props.userActiveRole
    console.log("paramRoles was set", paramsRoles2)


    const [formIsValid, setFormIsValid] = useState(false);

    const [questionsUniqueCriteria, setQuestionsUniqueCriteria] = useState([])
    const [questionsWithRole, setQuestionsWithRole] = useState([])
    const [answers, setAnswers] = useState({});
    const [filtered, setFiltered] = useState({})
    const [topic, setTopic] = useState('')
    const [criteria, setCriteria] = useState('')
    const [displayed, setDisplayed] =useState({})

    useEffect(async() => {
        await props.fetchUserActiveCriteria(params.userId)
        await props.fetchUserActiveRole(params.userId)

        console.log("Active role fetched to be: ", props.userActiveRole)


        setCriteria(props.userActiveCriteria)

    }, [props, params.userId])

    useEffect(() => {
        console.log("Criteria", criteria)
    
    }, [criteria])
        
const fetchQuestionsWithRole = async (roles) => {
    const encodedRole = encodeURIComponent(roles);
    const url = `/questions/${params.application}/questionsWithRole/${encodedRole}`;

    try {
        const response = await axiosInstance.get(url);
        if (response.data) {
            console.log('Questions data fetched for role:', response.data);
            setQuestionsWithRole(response.data);
            setTopic(response.data[0].topic);
        }
    } catch (error) {
        console.error(`There was an error retrieving the questions list: ${error}`);
    }
};

const fetchQuestionsUniqueCriteria = async (roles) => {
    const encodedRole = encodeURIComponent(roles);
    const url = `/questions/${params.application}/questionsUniqueCriteria/${encodedRole}`;

    try {
        const response = await axiosInstance.get(url);
        if (response.data) {
            setQuestionsUniqueCriteria(response.data);
        }
    } catch (error) {
        console.error(`There was an error retrieving the questions list: ${error}`);
    }
};


    useEffect(() => {
        
        if (criteria === -1) {
            navigate(`/${params.application}/complete/${params.userId}`);
        }

    }, [params.userId, navigate, criteria])

    useEffect(async() => {
        await props.fetchUserActiveCriteria(params.userId)
        await props.fetchUserActiveRole(params.userId)

        console.log("Active role fetched to be 2: ", props.userActiveRole)


    
        fetchQuestionsWithRole(props.userActiveRole)
        fetchQuestionsUniqueCriteria(props.userActiveRole)    
    }, [props.userActiveRole])

    useEffect(() => {    
        setAnswers(
            questionsWithRole.map((question) => question.questionId).reduce((acc,curr)=> {
                acc[curr]="unanswered" 
                return acc
            }, {} )
        )
    }, [questionsWithRole])
      
    useEffect(() => { 
        if (criteria >= 0 && questionsUniqueCriteria.length >= 1) {
            setFiltered(
                Object.keys(answers)
                    .filter(key => Object.values(questionsWithRole.filter((x) => x.criteria === questionsUniqueCriteria[criteria].criteria).map(q => q.questionId)).includes(key))
                    .reduce((obj, key) => {
                        return {
                            ...obj,
                            [key]: answers[key]
                        };
                    }, {}) 
            )
        }    
    }, [answers, questionsWithRole, questionsUniqueCriteria, criteria])
    
    const handleChange = (event) => {
        setAnswers({
            ...answers,
            [event.target.name]: event.target.value,
        });        
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFormIsValid(
                Object.keys(filtered).length > 0 && !Object.values(filtered).includes('unanswered')
            );
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [filtered]);

    useEffect(() => {
            
        if (questionsUniqueCriteria.length > 0 && criteria >= 0) {
            setDisplayed(
                questionsWithRole.filter((x) => x.criteria === questionsUniqueCriteria[criteria].criteria)
            )
        }
        
                
    }, [questionsUniqueCriteria, questionsWithRole, criteria]);

    useEffect(() => {
        if (Object.keys(displayed).length > 0) {
            setTopic(
                Object.values(displayed)[0].topic
            )
        }
    }, [displayed])

    const submitHandler = async (event) => {
        event.preventDefault();
        
        Object.entries(filtered).forEach(([id, value]) => {
            props.handleAnswerCreate(id, params.userId, props.userActiveRole, value);
        })
        if (criteria < questionsUniqueCriteria.length-1) {
            props.handleUserActiveCriteriaUpdate(params.userId, criteria+1)
            setCriteria(prev => ++prev)
            
        } else if (criteria === questionsUniqueCriteria.length-1) {
        
            await props.handleUserActiveCriteriaUpdate(params.userId, -1);
            setCriteria(-1)
            
            navigate(`/${params.application}/complete/${params.userId}`);
        
        }
        
        setFormIsValid(false)

    };

        
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={12} sx={{ minHeight: "20px" }}></Grid>
            <Grid item xs={12} md={10}>{topic.length >= 1 
                    ? 
                <Typography variant="h4">{topic.replace("|", " and ")}</Typography>
                : <></>}
            </Grid>
            <Grid item xs={12} md={10}>{questionsUniqueCriteria.length > 0 && criteria >= 0 
                    ? 
                <Typography variant="h5">{questionsUniqueCriteria[criteria].criteria.replace("|", " and ")}</Typography>
                : <></>}
            </Grid>
            <Grid item xs={12} md={10}>
                {displayed.length >= 1 
                    ? <List style={{maxHeight: '550px', overflow: 'auto'}}>
                        {displayed.map((question) => {
                            return (
                                <ListItem key={question.questionId}>
                                    <ListItemAvatar>
                                        <Avatar style={{backgroundColor: "#8c5656"}}>
                                            <ArrowForwardIosIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={question.question}/>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name={question.question}
                                        key={question.questionId}    
                                        onChange={handleChange}
                                        style={{minWidth:'150px', marginLeft:"10px"}}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio color="secondary"/>}
                                            name={question.questionId.toString()}
                                            checked={answers[question.questionId.toString()] === 'yes'}
                                            label="Yes"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<Radio color="secondary"/>}
                                            name={question.questionId.toString()}
                                            label="No"
                                            checked={answers[question.questionId.toString()] === 'no'}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </ListItem>
                            );
                        })}
                    </List>
                    : <></>
                }
            </Grid>
            <Grid item xs={12} md={10}>
            <Button 
                color="secondary" 
                type="submit" 
                onClick={submitHandler} 
                disabled={!formIsValid} 
                variant="outlined" 
                style={{ minWidth: '100%', margin: '5px 0px 5px 0px', padding: '10px' }}
            >
                {criteria === questionsUniqueCriteria.length - 1 ? 'Finish Assessment' : 'Continue'}
            </Button>
            </Grid>
        </Grid>
    );
}

export default QuestionMaturityAssessment;