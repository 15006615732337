import { Checkbox, FormGroup, FormControlLabel, FormControl, Grid, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import certificationsConfig from '../../data/certifications.json';
import rolesConfig from '../../data/roles.json';
import { useNavigate } from "react-router-dom";
import {useParams} from "react-router-dom";

const CertificationAdd = (props) => {
    const [formIsValid, setFormIsValid] = useState(false);
 
    const [checkedCertificates, setCheckedCertificates] = useState(Object.entries(certificationsConfig['certifications']).map(([id, value]) => value.certificate).reduce((acc,curr)=> {
        acc[curr]=false
        return acc
    }, {}));

    const navigate = useNavigate();

    const params = useParams()

    console.log("PARAMS: ", params)

    useEffect(async() => {
        await props.fetchUserActiveCriteria(params.userId)
        await props.fetchUserActiveRole(params.userId)
                
        if (props.userActiveCriteria === -1) {
            navigate(`/${params.application}/complete/${params.userId}`);
        } else if (props.userActiveCriteria > 1 && Object.values(rolesConfig['roles']).map(r => r.role).includes(props.userActiveRole) ) {
            console.log('params.roles:', props.userActiveRole);

            navigate(`/${params.application}/questions/${params.userId}`, { 
                state: { role: props.userActiveRole } 
        })
    }

    }, [props, params.roles, params.userId, navigate])

    const handleChange = (event) => {

        setCheckedCertificates((prev) => ({
            ...prev,
            [event.target.name]: event.target.checked,
        }));
        console.log("event: ", event.target.name, event.target.checked)
        
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const updateUser = JSON.stringify({
            [params.userId]: {
                "certifications": Object.getOwnPropertyNames(
                    Object.fromEntries(
                        Object.entries(
                            checkedCertificates
                        ).filter(
                            (item) => item[1] === true
                        )
                    )
                )
                .join("|")
            }
        });
        await props.handleUserUpdateCertifications(params.userId, Object.getOwnPropertyNames(Object.fromEntries(Object.entries(checkedCertificates).filter(item => item[1] === true))).join("|"));
        localStorage.setItem('certificationsObject', JSON.stringify(updateUser));
        console.log('params.roles:', params.roles);
        navigate(`/${params.application}/questions/${params.userId}`, { state: { role: params.roles}}) 
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setFormIsValid(
                Object.values(checkedCertificates).includes(true)
            );
        }, 500);

        return () => {
            clearTimeout(timeout);
        };

    }, [checkedCertificates]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={12} sx={{ minHeight: "20px" }}></Grid>
            <Grid item xs={12} md={10}>
                <FormControl fullWidth>
                    <FormGroup>
                        {Object.entries(certificationsConfig['certifications']).map(([id, current]) => 
                            <FormControlLabel 
                                style={{marginLeft: "100px"}}
                                key={id}    
                                control={<Checkbox
                                    key={id}
                                    value={current.certificate}
                                    color="secondary"
                                    name={current.certificate}
                                    checked={checkedCertificates[current.certificate]}
                                    onChange={handleChange}
                                />}
                                label={current.certificate}
                                labelPlacement="end"
                            />
                        )}
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={10}>
                <Button 
                color="secondary" type="submit" onClick={submitHandler} disabled={!formIsValid} variant="outlined" style={{ minWidth: '100%', margin: '5px 0px 5px 0px', padding: '10px' }}>
                    Continue
                </Button>
            </Grid>
        </Grid>
    );
}

export default CertificationAdd;