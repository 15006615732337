import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import HeaderBar from './components/ui/HeaderBar';
import MyRoutes from './components/routes/MyRoutes';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

import axios from 'axios';

const theme = createTheme({
  palette: {
    primary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#b2b2b2',
      contrastText: '#fff',
    },
    secondary: {
      light: 'hsl(36, 77%, 48%)',
      main: '#000000',
      dark: 'hsl(36, 96%, 27%)',
      contrastText: '#000',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'gray',
            },
            '&:hover fieldset': {
              borderColor: 'darkgray',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'gray',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'darkgray',
          '&.Mui-checked': {
            color: 'darkgray',
          },
          '&.MuiCheckbox-indeterminate': {
            color: 'darkgray',
          },
          '&:hover': {
            backgroundColor: 'rgba(128, 128, 128, 0.1)',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'gray',
            },
            '&:hover fieldset': {
              borderColor: 'darkgray',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'gray',
            },
          },
        },
      },
    },
  },
});

const App = () => {
  const backendIp = window.location.hostname;
  const protocol = 'http' // window.location.protocol.includes('https') ? 'https' : 'http';
  const backendPort = 4003;

  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userActiveAssessment, setUserActiveAssessment] = useState("");
  const [userActiveCriteria, setUserActiveCriteria] = useState("");
  const [userActiveComponent, setUserActiveComponent] = useState("");
  const [userActiveRole, setUserActiveRole] = useState("");
  const [userActiveApplicationsList, setActiveApplicationsList] = useState([]);
  const [userRegisteredApplicationsList, setRegisteredApplicationsList] = useState([]);
  const [userCompleteApplicationsList, setCompleteApplicationsList] = useState([]);

  const [userApplication, setUserApplication] = useState(
      localStorage.getItem("userApplication") || ""
    );
  useEffect(() => {
      localStorage.setItem("userApplication", userApplication);
    }, [userApplication]);


  const [activeUserId, setActiveUserId] = useState(
    localStorage.getItem("activeUserId") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeUserId", activeUserId);
  }, [activeUserId]);

  useEffect(() => {
      const token = localStorage.getItem('token');
      const currentPath = window.location.pathname;

      // If there's no token, navigate to login unless on reset-password page
      if (!token) {
        // Allow reset-password route without token
        if (!currentPath.startsWith('/reset-password')) {
          navigate('/login');
        }
      } else {
        setAuthToken(token);
      }

      setLoading(false);
  }, [navigate]);

    useEffect(() => {
      const interceptor = axios.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem('token');
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      return () => {
        axios.interceptors.request.eject(interceptor);
      };
    }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

const handleAnswerCreate = async (questionId, userId, answer, followupAnswer, role) => {
  try {
    const response = await axios.post(`/api/answers/${userApplication}/create`, {
      answerId: questionId + userId,
      questionId: questionId,
      userId: userId,
      answer: answer,
      followupAnswer: followupAnswer,
      role: role,
      date: Date.now().toString(),
    });

  } catch (error) {
    console.error(`There was an error creating the question: ${error}`);
  }
};

  const fetchUserActiveAssessment = async (userId) => {
    try {
      const response = await axios.get(`/api/users/${userApplication}/activeAssessment/${userId}`);
      setUserActiveAssessment(response.data[0].activeAssessment);
      return response?.data?.[0]?.activeAssessment !== undefined ? response.data[0].activeAssessment : "";
    } catch (error) {
      console.error(`There was an error retrieving the active assessment: ${error}`);
    }
  };

  const fetchUserActiveCriteria = async (userId) => {
    try {
      const response = await axios.get(`/api/users/${userApplication}/activeCriteria/${userId}`);
      setUserActiveCriteria(response.data[0].activeCriteria);
      return response?.data?.[0]?.activeCriteria !== undefined ? response.data[0].activeCriteria : "";
    } catch (error) {
      console.error(`There was an error retrieving the active criteria: ${error}`);
    }
  };

 const fetchUserActiveComponent = async (userId) => {
  try {
    const response = await axios.get(`/api/users/${userApplication}/activeComponent/${userId}`);
    setUserActiveComponent(response.data[0].activeComponent);
    return response?.data?.[0]?.activeComponent !== undefined ? response.data[0].activeComponent : "";
  } catch (error) {
    console.error(`There was an error retrieving the active component: ${error}`);
  }
};

  const fetchUserActiveRole = async (userId) => {
    try {
      const response = await axios.get(`/api/users/${userApplication}/role/${userId}`);
      setUserActiveRole(response.data[0].role);
      return response?.data?.[0]?.role !== undefined ? response.data[0].role : "";
    } catch (error) {
      console.error(`There was an error retrieving the active role: ${error}`);
    }
};

const handleUserUpdateRole = async (userId, role) => {
  try {
    await axios.put(
      `/api/users/${userApplication}/updateRole`,
      { userId: userId, role: role, application: userApplication }
    );
  } catch (error) {
    console.error(`There was an error updating user role: ${error}`);
  }
};


const handleUserUpdateCertifications = async (userId, certifications) => {
  const url = `/api/users/${userApplication}/updateCertifications`;
  const requestBody = {
    userId: userId,
    certifications: certifications,
    application: userApplication,
  };
  try {
    const response = await axios.put(url, requestBody);
  } catch (error) {
    console.error(`There was an error updating user certifications: ${error}`);
  }
};

const handleUserActiveCriteriaUpdate = async (userId, activeCriteria) => {
  try {
    const response = await axios.put(`/api/users/${userApplication}/updateActiveCriteria`, {
      userId: userId,
      activeCriteria: activeCriteria,
      application: userApplication,
    });
  } catch (error) {
    console.error(`There was an error updating user criteria: ${error}`);
  }
};


const handleUserActiveComponentUpdate = async (userId, activeComponent) => {
  try {
    const res = await axios.put(`/api/users/${userApplication}/updateActiveComponent`, {
      userId: userId,
      activeComponent: activeComponent,
      application: userApplication,
    });
  } catch (error) {
    console.error(`There was an error updating active component: ${error}`);
  }
};

const handleUserActiveAssessmentUpdate = async (userId, activeAssessment) => {
  try {
    const response = await axios.put(
      `/api/users/${userApplication}/updateActiveAssessment`,
      {
        userId: userId,
        activeAssessment: activeAssessment,
        application: userApplication,
      });
  } catch (error) {
    console.error(`Error updating active assessment: ${error}`);
  }
};

const fetchUserActiveApplicationForGivenApplication = async (userId) => {
  try {
    const response = await axios.get(`/api/users/${userApplication}/activeApplication/${userId}`);
    setUserActiveComponent(response.data[0].activeApplication);
  } catch (error) {
    console.error(`There was an error retrieving the active component: ${error}`);
  }
};

const handleUserActiveApplicationUpdate = async (userId, activeApplication) => {
  try {
    const apiUrl = `/api/users/${activeApplication}/updateActiveApplication/${userId}`;
    const response = await axios.put(
      apiUrl,
      {
        userId: userId,
        application : activeApplication,
        activeApplication: 1
      }
    );
     localStorage.setItem("userApplication", activeApplication);
     setUserApplication(activeApplication)
     setActiveUserId(userId)// Ensure state updates after successful API call
  } catch (error) {
    console.error(`Error updating active application: ${error}`);
  }
};

const fetchUserActiveApplicationsForSpecificUser = async (userId) => {
  try {
    const res = await axios.get(`/api/users/activeApplicationsForSpecificUser/${userId}`);
    setActiveApplicationsList(res.data);
    return res.data;
  } catch (error) {
    console.error(`There was an error getting list of active applications: ${error}`);
    return [];
  }
};

const fetchUserCompleteApplicationsForSpecificUser = async (userId) => {
  try {
    const res = await axios.get(`/api/users/completeApplicationsForSpecificUser/${userId}`);
    setCompleteApplicationsList(res.data);
    return res.data;
  } catch (error) {
    console.error(`There was an error getting list of complete applications: ${error}`);
    return [];
  }
};

const fetchUserRegisteredApplicationsForSpecificUser = async (userId) => {
  try {
    const res = await axios.get(`/api/users/registeredApplicationsForSpecificUser/${userId}`);
    setRegisteredApplicationsList(res.data);
    return res.data;
  } catch (error) {
    console.error(`There was an error getting list of registered applications: ${error}`);
    return [];
  }
};

  return (
      <ThemeProvider theme={theme}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 10 }}
            columnSpacing={{ xs: 2, md: 3 }}
          >
            <Grid item xs={12} md={12}>
            <HeaderBar
              activeUserId = {activeUserId}
              userApplication={userApplication}>
              </HeaderBar>
            </Grid>
          </Grid>
          <Grid sx={{ margin: "10px" }}>
            <MyRoutes
             setAuthToken={setAuthToken}
              handleAnswerCreate={handleAnswerCreate}
              handleUserUpdateRole={handleUserUpdateRole}
              handleUserUpdateCertifications={handleUserUpdateCertifications}
              handleUserActiveAssessmentUpdate={handleUserActiveAssessmentUpdate}
              fetchUserActiveAssessment={fetchUserActiveAssessment}
              userActiveAssessment={userActiveAssessment}
              handleUserActiveComponentUpdate={handleUserActiveComponentUpdate}
              fetchUserActiveComponent={fetchUserActiveComponent}
              userActiveComponent={userActiveComponent}
              fetchUserActiveRole={fetchUserActiveRole}
              userActiveRole={userActiveRole}
              setUserApplication = {setUserApplication}
              userApplication={userApplication}
              fetchUserActiveApplicationForGivenApplication = {fetchUserActiveApplicationForGivenApplication}
              handleUserActiveApplicationUpdate = {handleUserActiveApplicationUpdate}
              fetchUserActiveApplicationsForSpecificUser = {fetchUserActiveApplicationsForSpecificUser}
              userActiveApplicationsList={userActiveApplicationsList}
              fetchUserCompleteApplicationsForSpecificUser = {fetchUserCompleteApplicationsForSpecificUser}
              userCompleteApplicationsList = {userCompleteApplicationsList}
              handleUserActiveCriteriaUpdate={handleUserActiveCriteriaUpdate}
              fetchUserActiveCriteria={fetchUserActiveCriteria}
              userActiveCriteria={userActiveCriteria}
              userRegisteredApplicationsList = {userRegisteredApplicationsList}
              fetchUserRegisteredApplicationsForSpecificUser = {fetchUserRegisteredApplicationsForSpecificUser}         >
            </MyRoutes>
          </Grid>
      </ThemeProvider>
  );
};

export default App;
