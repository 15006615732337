import { Tabs, Tab, Grid, Card, CardContent, Box } from '@mui/material';
import React, { useState } from 'react';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import QuestionPlatformSolution from '../components/ui/QuestionPlatformSolution';
import QuestionProjectAssessment from '../components/ui/QuestionProjectAssessment';
import QuestionMaturityAssessment from '../components/ui/QuestionMaturityAssessment';
import {useParams} from "react-router-dom";

const QuestionsMenu = (props) => {
    const [useTabState, setTabState] = useState(0);
    const params = useParams()
    const handleChange = (event, newValue) => {
        setTabState(newValue);
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={10}>
                <Grid item xs={12}>
                    <Box sx={{ minHeight: 150 }}></Box>
                </Grid>
                <Card sx={{ borderRadius: "25px" }}>
                    <CardContent sx={{ minWidth: "100%", margin: "0px", padding: "0px" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={{ xs: 3, md: 3 }}
                            columnSpacing={{ xs: 2, md: 3 }}
                            sx={{ backgroundColor: "#08440c", minWidth: "100%", margin: "0px", padding: "0px" }}
                        >
                            <Grid item xs={12} md={10}>
                                <Tabs indicatorColor="primary" textColor="primary" value={useTabState} onChange={handleChange} aria-label="icon label tabs example">
                                    <Tab sx={{ color: "rgb(255, 255, 255, 0.7)" }} icon={<QuestionAnswerRoundedIcon/>} label="Questions" />
                                </Tabs>
                            </Grid>
                        </Grid>
                        {   params.application === 'platform-solution-assessment' ? (
                                {
                                    '0': <QuestionPlatformSolution
                                            questionData={props.questionData} 
                                            handleAnswerCreate={props.handleAnswerCreate} 
                                            handleUserActiveAssessmentUpdate={props.handleUserActiveAssessmentUpdate} 
                                            userActiveAssessment={props.userActiveAssessment} 
                                            fetchUserActiveAssessment={props.fetchUserActiveAssessment}
                                            handleUserActiveComponentUpdate={props.handleUserActiveComponentUpdate}
                                            userActiveComponent={props.userActiveComponent}
                                            useApplication={props.userApplication}
                                            fetchUserActiveComponent={props.fetchUserActiveComponent}
                                        />,
                                }[useTabState]
                            ) :  params.application === 'project-assessment' ? (
                                {
                                    '0': <QuestionProjectAssessment
                                            questionData={props.questionData} 
                                            handleAnswerCreate={props.handleAnswerCreate} 
                                            handleUserActiveAssessmentUpdate={props.handleUserActiveAssessmentUpdate} 
                                            userActiveAssessment={props.userActiveAssessment} 
                                            fetchUserActiveAssessment={props.fetchUserActiveAssessment}
                                            handleUserActiveComponentUpdate={props.handleUserActiveComponentUpdate}
                                            userActiveComponent={props.userActiveComponent}
                                            useApplication={props.userApplication}
                                            fetchUserActiveComponent={props.fetchUserActiveComponent}
                                        />,
                                }[useTabState]
                            )  : params.application === 'maturity-assessment' ? (
                                {
                                    '0': <QuestionMaturityAssessment
                                    questionData={props.questionData} 
                                    handleAnswerCreate={props.handleAnswerCreate} 
                                    handleUserActiveCriteriaUpdate={props.handleUserActiveCriteriaUpdate} 
                                    userActiveCriteria={props.userActiveCriteria} 
                                    userActiveRole={props.userActiveRole}
                                    fetchUserActiveRole = {props.fetchUserActiveRole}    
                                    fetchUserActiveCriteria={props.fetchUserActiveCriteria}/>,
                                }[useTabState]
                            ) 
                            : null
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );

}

export default QuestionsMenu;