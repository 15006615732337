import { Grid, Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import Welcome from '../components/ui/Welcome';

const HomePage = (props) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                    <Box sx={{ minHeight: 150 }}></Box>
                </Grid>
                <Card sx={{ borderRadius: "25px" }}>
                    <CardContent sx={{ minWidth: "100%", margin: "0px", padding: "0px" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={{ xs: 3, md: 3 }}
                            columnSpacing={{ xs: 2, md: 3 }}
                            sx={{ backgroundColor: "#08440c", minWidth: "100%", margin: "0px", padding: "0px" }}
                        >
                            <Grid item xs={12} md={10}>
                                <Typography sx={{ indicatorColor:"primary", textColor:"primary", color: "rgb(255, 255, 255, 0.9)", margin: "8px" }} variant="h4">Welcome!</Typography>
                            </Grid>
                        </Grid>
                        <Welcome 
                            userActiveAssessment={props.userActiveAssessment} 
                            fetchUserActiveAssessment={props.fetchUserActiveAssessment}
                            fetchUserActiveComponent={props.fetchUserActiveComponent}
                            userActiveComponent={props.userActiveComponent}
                            setUserApplication={props.setUserApplication}
                            userApplication={props.userApplication}
                            setUserActiveApplication ={props.setUserActiveApplication}
                            handleUserActiveApplicationUpdate={props.handleUserActiveApplicationUpdate}
                            fetchUserActiveApplicationForGivenApplication = {props.fetchUserActiveApplicationForGivenApplication}
                            fetchUserActiveApplicationsForSpecificUser = {props.fetchUserActiveApplicationsForSpecificUser}
                            userActiveApplicationsList = {props.userActiveApplicationsList}
                            fetchUserCompleteApplicationsForSpecificUser = {props.fetchUserCompleteApplicationsForSpecificUser}
                            userCompleteApplicationsList = {props.userCompleteApplicationsList}
                            userActiveCriteria={props.userActiveCriteria} 
                            fetchUserActiveCriteria={props.fetchUserActiveCriteria} 
                            fetchUserActiveRole = {props.fetchUserActiveRole}
                            userRegisteredApplicationsList = {props.userRegisteredApplicationsList}
                            fetchUserRegisteredApplicationsForSpecificUser = {props.fetchUserRegisteredApplicationsForSpecificUser}    
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    );
}

export default HomePage;