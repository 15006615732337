import { Grid, Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import Complete from '../components/ui/Complete';

const CompletePage = () => {
    
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                    <Box sx={{ minHeight: 150 }}></Box>
                </Grid> 
                <Card sx={{ borderRadius: "25px" }}>
                    <CardContent sx={{ minWidth: "100%", margin: "0px", padding: "0px" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            rowSpacing={{ xs: 3, md: 3 }}
                            columnSpacing={{ xs: 2, md: 3 }}
                            sx={{ backgroundColor: "#08440c", minWidth: "100%", margin: "0px", padding: "0px" }}
                        >
                            <Grid item xs={12} md={10}>
                                <Typography sx={{ indicatorColor:"primary", textColor:"primary", color: "rgb(255, 255, 255, 0.9)", margin: "8px" }} variant="h4">Assessment Complete!</Typography>
                            </Grid>
                        </Grid>
                        <Complete/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    );
}

export default CompletePage;