import React, { useState } from 'react';
import { Grid, Box, Card, CardContent, Typography, TextField, Button, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axiosInstance from '../services/axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';

const RegisterPage = () => {
    const { client } = useParams();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [selectedAssessments, setSelectedAssessments] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const backendIp = window.location.hostname;
    const protocol = 'http' //window.location.protocol.includes('https') ? 'https' : 'http';
    const backendPort = 4003;

    // Topics based on selected assessments
    const topicsByAssessment = {
        'maturity-assessment': ["General Consulting Skills", "General Developer Skills", "Tools/Languages", "Quantexa", "Business knowledge", "General technical skills"],
        'project-assessment': ["Assessment", "Teams", "Documentation", "Project Management", "Leadership|Governance"],
        'platform-solution-assessment': ["Infrastructure", "Technical Debt", "Quantexa Components", "Design", "Data"]
    };

    // Mapping of internal assessment values to display labels
    const assessmentLabels = {
        'maturity-assessment': 'Resource Capability Assessment',
        'project-assessment': 'Project-Delivery Assessment',
        'platform-solution-assessment': 'Platform-Solution Assessment'
    };

    // Define assessments that require topic selection
    // The goal is to allow users to manually select topics for assessments in the assessmentsForTopicSelection array,
    // while other assessments automatically add their topics to the selected list without requiring user input.
    const assessmentsForTopicSelection = ["platform-solution-assessment"];

    const roles = [
        "Tech Lead", "Junior Developer", "Developer", "Senior Developer", "Architect", "Junior Business Analyst",
        "Business Analyst", "Senior Business Analyst", "Technical Delivery Oversight",
        "Project Manager", "Product Owner"
    ];  // Ensure roles are an array (not an object)

    // Handle assessment checkbox changes
    const handleAssessmentChange = (event) => {
        const value = event.target.value;
        const newSelectedAssessments = selectedAssessments.includes(value)
            ? selectedAssessments.filter(assessment => assessment !== value)
            : [...selectedAssessments, value];

        setSelectedAssessments(newSelectedAssessments);

        // Automatically select all topics for assessments NOT in assessmentsForTopicSelection
        const autoSelectedTopics = newSelectedAssessments.flatMap(assessment =>
            assessmentsForTopicSelection.includes(assessment) ? [] : topicsByAssessment[assessment]
        );

        setSelectedTopics((prevSelectedTopics) => {
            const updatedTopics = [...new Set([...prevSelectedTopics, ...autoSelectedTopics])]; // Avoid duplicates
            return updatedTopics;
        });
    };

    // Handle topic checkbox changes for assessments in assessmentsForTopicSelection
    const handleTopicChange = (event) => {
        const value = event.target.value;
        setSelectedTopics(prev =>
            prev.includes(value)
                ? prev.filter(topic => topic !== value)
                : [...prev, value]
        );
    };

const handleSubmit = async (event) => {
    event.preventDefault();

        if (!email || !role || selectedAssessments.length === 0 ) {
        setError('All fields, including topics, are required.');
        return;
    }

    // Check if any assessment from assessmentsForTopicSelection is selected but no topic is chosen for it
    const missingTopicsForAssessments = selectedAssessments.some(assessment =>
        assessmentsForTopicSelection.includes(assessment) &&
        !topicsByAssessment[assessment].some(topic => selectedTopics.includes(topic))
    );

    if (missingTopicsForAssessments) {
        setError('You must select at least one topic for the selected assessments that require topics.');
            return;
        }
    setError('');
    setSuccess('');

    try {
        const token = localStorage.getItem('token');

            await axiosInstance.post(`/userinfo/register`, {
                email,
                client,
                role,
                assessments: selectedAssessments,
                topics: selectedTopics
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        setSuccess('Registration successful!');
        setEmail('');
        setRole('');
        setSelectedAssessments([]);
        setSelectedTopics([]);

        navigate('/registeredUsers/' + client);
    } catch (err) {
        console.error('Error during registration:', err);
            setError('Registration failed. Please try again.');
        }
    };


    // Filter and display topics grouped by selected assessments that are in assessmentsForTopicSelection
    const renderGroupedTopics = () => {
        return selectedAssessments
            .filter(assessment => assessmentsForTopicSelection.includes(assessment))
            .map((assessment) => (
                <Grid item xs={12} key={assessment}>
                    <Typography variant="h6">{assessmentLabels[assessment]} Topics</Typography>
                    {topicsByAssessment[assessment].map((topic) => (
                        <FormControlLabel
                            key={topic}
                            control={
                                <Checkbox
                                    checked={selectedTopics.includes(topic)} // Checked if already selected
                                    onChange={handleTopicChange}
                                    value={topic}
                                />
                            }
                            label={topic}
                        />
                    ))}
                </Grid>
            ));
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                    <Box sx={{ minHeight: 150 }}></Box>
                </Grid>
                <Card sx={{ borderRadius: "25px" }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>Register New User</Typography>
                        {error && <Typography color="error">{error}</Typography>}
                        {success && <Typography color="primary">{success}</Typography>}
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        fullWidth
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth required>
                                        <InputLabel>Role</InputLabel>
                                        <Select
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 48 * 6 + 8, // 6 options with 48px height
                                                    },
                                                },
                                            }}
                                        >
                                            {roles.map((role) => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Select Assessments</Typography>
                                    {Object.keys(assessmentLabels).map((assessment) => (
                                        <FormControlLabel
                                            key={assessment}
                                            control={
                                                <Checkbox
                                                    checked={selectedAssessments.includes(assessment)}
                                                    onChange={handleAssessmentChange}
                                                    value={assessment}
                                                />
                                            }
                                            label={assessmentLabels[assessment]}
                                        />
                                    ))}
                                </Grid>

                                {/* Render grouped topics by selected assessments */}
                                {selectedAssessments.length > 0 && renderGroupedTopics()}

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                    >
                                        Register
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default RegisterPage;
