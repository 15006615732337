import React, { useState } from 'react';
import { Grid, Box, Card, CardContent, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const ResetPasswordPage = () => {
    const { token } = useParams(); // Get the token from the URL
    const navigate = useNavigate(); // Initialize useNavigate
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const backendIp = window.location.hostname;
    const protocol = 'http' //window.location.protocol.includes('https') ? 'https' : 'http';
    const backendPort = 4003;

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Basic validation
        if (!newPassword || !confirmPassword) {
            setError('Both password fields are required.');
            return;
        }
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        // Clear previous messages
        setError('');
        setSuccess('');

        try {
            // Sending the new password and token to the server
            await axios.post(`/api/admin/reset-password`, {
                token,
                newPassword
            });
            setSuccess('Password reset successfully! You can now log in.');
            setNewPassword('');
            setConfirmPassword('');

            // Redirect to the login page or admin dashboard
            navigate('/login'); // Adjust based on your routing setup
        } catch (err) {
            setError('Password reset failed. Please try again.');
        }
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={{ xs: 3, md: 3 }}
            columnSpacing={{ xs: 2, md: 3 }}
        >
            <Grid item xs={12} md={6}>
                <Box sx={{ minHeight: 150 }}></Box>
                <Card sx={{ borderRadius: "25px" }}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>Reset Password</Typography>
                        {error && <Typography color="error">{error}</Typography>}
                        {success && <Typography color="primary">{success}</Typography>}
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="New Password"
                                        fullWidth
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        type="password"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Confirm Password"
                                        fullWidth
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        type="password"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                    >
                                        Reset Password
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ResetPasswordPage;
